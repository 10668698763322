@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai:wght@100;200;300;400;500;600;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}


p{
  text-align: left;
}

body {
  
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
  font-family: 'IBM Plex Sans Thai', sans-serif;
}

.modal-open{
  overflow: hidden !important;
}

.modal-content{
  background-color: transparent;
  border: 0;
  border-radius: 20px;
}

/* html {
  overflow: scroll;
} */

::-webkit-scrollbar {
  height: 0;
  /* Remove scrollbar space */
  width: 0;
  /* Remove scrollbar space */
  background: transparent;
  /* Optional: just make scrollbar invisible */
}

/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: #ff0000;
}

a {
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.grecaptcha-badge{
  visibility: hidden;
  
}

.swal2-container {
  z-index: 999;
  overflow-y: hidden;
  background: rgb(0,0,0,0.6) !important;
}

.swal2-title{
  font-size: 1.5em;
  color:black;
}

.swal2-confirm {
background-color: rgba(245,194,64,1) !important;
}



.show{
visibility: visible;
}

.RSPBprogressBar{
  height: 5px !important;
}